import React from "react"
import styles from "./bottomImage.module.css"
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'

export default ({ block }) => {
  return (
    <section className={styles.section}>
      <div className={styles.background}><img className={styles.image} src={block.image} alt="" /></div>
      <div className={styles.gradient}></div>
      <div className={styles.content}>
        <Fade top duration={500} delay={400} distance='40px'>
          <h2 className={styles.text}>{block.text && block.text}</h2>
        </Fade>
        <Fade bottom duration={500} delay={400} distance='40px'>
          <div className={styles.buttonContainer}>
            {block.buttonText && block.buttonLink &&
              <Link to={block.buttonLink}>
                <button className={styles.button}>{block.buttonText}</button>
              </Link>}
          </div>
        </Fade>
      </div>
    </section>
  )
}