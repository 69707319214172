import React from "react"
import styles from "./menu.module.css"
import { Link } from "gatsby"

export default function Menu ({ links, setOpen, open, logo }) {
 
  return (
    <div className={styles.menu + `${open ? ` ${styles.menuOpen}` : ` ${styles.menuClosed}`}`}>
      <div>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={logo} alt=""/>
        </div>
      </div>
      <div className={styles.burger} onClick={() => setOpen(!open)}>
        <div className={styles.burgerTop}></div>
        <div className={styles.burgerMiddle}></div>
        <div className={styles.burgerBottom}></div>
      </div>
      <div className={styles.menuLinkContainer}>
        {links.map((link, i) => {
          return (
            <div className={styles.menuLinks} key={i} >
              <Link
                activeClassName={styles.active}
                className={styles.menuLinkText}
                to={link.slug}>
                  {link.linkText}
              </Link>
            </div>
          )  
        })}
      </div>
    </div>
  )
}