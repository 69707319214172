import React, { useState } from 'react'
import styles from './formBlock.module.css'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'

export default ({ block }) => {
  const [toggle, setToggle] = useState(true);
 
  return (
    <section className={styles.section}>
      <div className={styles.formsContainer}>
        <Fade duration={500} delay={500} distance="40px">
          <h2 className={styles.heading}>{block.formHeading}</h2>
          {/* <div onClick ={() => setToggle(!toggle)} className={styles.toggleContainer}>
            <p className={styles.optionOne + ` ${toggle ? "" : `${styles.optionSelect}`}`}>{block.toggleOptionOne}</p>
            <div className={styles.switch}>
              <div className={styles.toggle + ` ${toggle ? `${styles.toggleOn}` : ""}`}></div>
            </div>
            <p className={styles.optionTwo + ` ${toggle ? `${styles.optionSelect}` : ""}`}>{block.toggleOptionTwo}</p>
          </div> */}
          {block.forms && block.forms.map((form, i) => {
            return (
              <div key={i} className={styles.formContainer}>
                <a ley={i} className={styles.form} href={form.pdf ? form.pdf : form.onlineLink ? form.onlineLink : ""} target={"_blank"} rel="noreferrer">
                  <Flip><img className={styles.icon} src={form.icon && form.icon} alt=""/></Flip>
                  <p className={styles.title}>{form.title}</p>
                  <p className={styles.download + ` ${!form.onlineLink ? `${styles.downloadShow}` : ""}`}>download</p>
                </a>
              </div>
            )
          })}
        </Fade>
      </div>
      <div className={styles.contactContainer}>
      <Fade duration={500} delay={500} distance="40px">
          <h2 className={styles.heading}>{block.contactHeading}</h2>
          <p className={styles.subHeading}>{block.contactSubHeading}</p>
          {block.numbers && block.numbers.map((number, i) => {
            return (
              <div key={i} className={styles.number}>
                <p className={styles.numberText}>{number.text}</p>
                <a className={styles.phone} href={`tel:${number.number.split(/[ -]+/).join('')}`}>{number.number}</a>
              </div>
            )
          })}
          <div className={styles.emailFax}>
            <div className={styles.number}>
              {block.fax &&
              <p className={styles.numberText}>Fax</p>}
              <p className={styles.phone}>{block.fax}</p>
            </div>
            <div className={styles.number}>
              {block.email &&
              <p className={styles.numberText}>Email</p>}
              <a href={`mailto:${block.email}`} className={styles.email}>{block.email}</a>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  )
}