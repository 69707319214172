import React, { useState, useEffect } from "react"
import Links from "./links"
import styles from "./header.module.css"

export default function Header({ image, smallLogo, alt_text, leftNavItems, rightNavItems, 
  open, setOpen, setContactOpen, admin }) {
  const [start, setStart] = useState(null);
  const [width, setWidth] = useState(null);
  const [scrolled, setScrolled] = useState(false);

  function updateScroll() {
    if (window.scrollY > 50)
      setScrolled(true);
    else
      setScrolled(false);
  }
  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
    return (() => {
      window.removeEventListener('scroll', updateScroll);
    })
  },[])

  return (
    <div className={styles.headerContainer}>
      <div className={styles.burger} onClick={() => setOpen(!open)}>
        <div className={styles.burgerTop}></div>
        <div className={styles.burgerMiddle}></div>
        <div className={styles.burgerBottom}></div>
      </div>
      <div className={styles.headerTopContainer + ` ${scrolled ? `${styles.headerTopScrolled}` : ""}`}>
        <div>
          <Links links={leftNavItems} linkClass={styles.leftLinks} setStart={setStart} setWidth={setWidth}/>
        </div>
        <div className={styles.logos}>
          <div className={styles.logoContainer}>
              <a className={styles.logoLink} href="/"><img className={styles.logo + ` ${scrolled ? `${styles.logoHide}` : ""}`} src={image} alt={alt_text}/></a>
          </div>
          <div className={styles.smallLogoContainer}>
              <img className={styles.smallLogo + ` ${scrolled ? `${styles.smallLogoShow}` : ""}`} src={smallLogo} alt={alt_text}/>
          </div>
        </div>
        <div>
          <Links setContactOpen={setContactOpen} links={rightNavItems} linkClass={styles.rightLinks} setStart={setStart} setWidth={setWidth} admin={admin}/>
        </div>
      </div>
      <div className={styles.highlightContainer + ` ${scrolled ? `${styles.highlightScrolled}` : ""}`}>
        <div className={styles.highlighter} id="highlighter"
          style={{transform: `translateX(${start}px)`, width: `${width}px`}}>
        </div>
      </div>
    </div>
  )
}