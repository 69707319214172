import React from 'react'
import styles from './twoColumnText.module.css'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import ArrowRight from "../../../static/uploads/arrow-right.svg"

export default ({ block }) => {
  
  return (
    <section>
      <div className={styles.container}>
        <div className={styles.headings}>
          {block.headingLines && block.headingLines.map((line, i) => {
            return (
              <Fade key={i} bottom duration={500} delay={400} distance='40px'>
                <h2 className={styles.heading + ` ${i === block.headingLines.length - 1 ? `${styles.color}`: ""}`}>
                  {line.headingLine}
                </h2>
              </Fade>
            )
          })}
        </div>
        <div className={styles.paragraphContainer}>
          <Fade top duration={500} delay={400} distance='40px'>
            <p className={styles.paragraph}>{block.paragraph}</p>
            {block.paragraphTwo && 
            <p className={styles.paragraph}>{block.paragraphTwo}</p>}
          </Fade>  
          {block.buttonText && block.buttonLink &&
            <Fade right duration={500} delay={400} distance='40px'>
              <Link to={block.buttonLink}>
                <p className={styles.textLink}>
                  {block.buttonText}
                  <span className={styles.arrow}><ArrowRight className={styles.icon} /></span>
                </p>
              </Link>
            </Fade>}
          {block.list && block.list.length > 0 &&
            <ul className={styles.list}>
              <Fade right duration={500} delay={400} distance='40px'>
                {block.list.map((item, i) => {
                  return (
                    <li key={i} className={styles.item}>{item}</li>
                  )
                })}
              </Fade>
            </ul>}
        </div>
      </div>
    </section>
  )
}