import React from "react"
import styles from "./header.module.css"
import { Link } from "gatsby"

function Links ({setStart, setWidth, links, linkClass, setContactOpen, admin}) {

  const curr = (el) => {
    if (el && window.location.href.includes(el.firstElementChild.href)) {
      setHighlight(el);
      window.onresize = function(el) {
        setHighlight(el);
      }
    }
  }
  function setHighlight(el) {
    if (el.offsetLeft < window.innerWidth * 0.5)
      setStart(el.offsetLeft + 7);
    else
      setStart(el.offsetLeft);
    setWidth(el.offsetWidth);
  }
  return (
    <div className={linkClass}>
      <div className={styles.linkContainer}>
        {links.map((link, i) => {
          return (
            <div className={styles.links} ref={curr} key={i} >
              <Link
                activeClassName='active'
                className={styles.linkText + `${link.linkText === 'Schedule an Appointment' ? ` ${styles.scheduleLink}` : ''}`}
                to={link.slug}>
                  {link.linkText}
              </Link>
            </div>
          )  
        })}
        <div className={styles.links} style={{display: "block"}}><button onClick={() => {setContactOpen(true); window.scrollTo(0,0);}} className={styles.contact}>Contact</button></div>
        <div className={styles.links} style={{display: "block"}}><a className={styles.contact} href={admin} target="_blank" rel="noreferrer">Admin</a></div>
      </div>
    </div>
  )
}

export default Links