import React, { useState, useRef, useEffect } from 'react'
import styles from './imageChanger.module.css'
import Fade from 'react-reveal/Fade'
import ArrowRight from "../../../static/uploads/arrow-right.svg"

export default ({ block }) => {
  const [selected, setSelected] = useState(0);
  const ref = useRef(null);

  function handleClick() {
    const container = ref.current;
    if (container) {
      container.scrollLeft = container.scrollLeft + container.offsetWidth;
    }  
  }
  
  function handleBack() {
    const container = ref.current;
    if (container) {
      container.scrollLeft = container.scrollLeft - container.offsetWidth;
    }  
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Fade right duration={500} delay={500} distance='40px'>
          <div className={styles.imageContainer}>
            {block.images && block.images.map((image, i) => {
              return (
                <div key={i} className={styles.image + ` ${selected === i ? `${styles.imageShow}` : ""}`}>
                  <img key={i} src={image.image} alt=""/>
                </div>
              )
            })}
          </div>
        </Fade>
        {/* <Fade right duration={500} delay={500} distance='40px'> */}
          <div className={styles.selectorContainer} ref={ref}>
              {block.images && block.images.map((image, i) => {
                return (
                  <div key={i} onClick={() => setSelected(i)} 
                    className={styles.smallImage + ` ${selected === i ? `${styles.smallImageShow}` : ""}`}>
                    <img key={i} src={image.image} alt=""/>
                  </div> 
                )
              })}
          </div>
        {/* </Fade> */}
        <button className={styles.back} onClick={handleBack}><ArrowRight className={styles.iconBack} /></button>
        <button className={styles.arrow} onClick={handleClick}><ArrowRight className={styles.icon} /></button>
      </div>
    </section>
  )
}