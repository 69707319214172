import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import styles from './map.module.css'

export default ({ block }) => {
  const mapStyles = {        
    height: "82vh",
    width: "100%"};
  
  const defaultCenter = {
    lat: +block.latitude, lng: +block.longitude
  }
  
  return (
    <div style={{position: 'relative'}}>
      <div className={styles.info} style={{position: 'absolute', top: '45vh', right: '30vw', zIndex: 5}}>
        <p className={styles.title}>{block.title}</p>
        <p className={styles.address}>{block.address}</p>
        <a className={styles.link} href={block.directionsUrl} target="_blank" rel="noreferrer">
          <p>{block.linkText}<span className={styles.icon}><img className={styles.arrow} src={block.icon} alt=""/></span></p>
        </a>
      </div>
      <LoadScript
       googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API}>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={defaultCenter}
        >
          <Marker icon={block.marker} position={defaultCenter} />
        </GoogleMap>
      </LoadScript>
    </div>
  )
}