import React from 'react'
import styles from './singleIcon.module.css'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'

export default ({ block }) => {
  
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Fade right duration={500} delay={500} distance='40px'>
          <Flip>
            <img className={styles.icon} src={block.icon && block.icon} alt="" />
          </Flip>
          <p className={styles.text}>{block.text && block.text}</p>
        </Fade>
      </div>
    </section>
  )
}