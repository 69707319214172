import React from 'react'
import { Link } from 'gatsby'
import styles from './hero.module.css'
import Fade from 'react-reveal/Fade'

export default({block, images}) => {

  return (
    <section className={styles.section}>
      {block.video && (
        <div className={styles.hero}><video className={styles.video} poster='/uploads/video-placeholder.png' playsInline autoPlay muted loop src={block.video} /></div>)}
    
      {block.image && (
        <div className={styles.hero}><img className={styles.image} src={block.image} images={images} alt="" /></div>)}  
        <div className={styles.gradient}></div>
        <div className={styles.container}>
          <Fade top duration={500} delay={400} distance='40px'>
            <h2 className={styles.heading}>{block.text && block.text}</h2>
          </Fade>
          {/* anchor for forms */}
          <div id="forms" />
          <Fade bottom duration={500} delay={400} distance='40px'>
            <div className={styles.buttons}>
              {block.buttons && block.buttons.map((button, i) => {
                return (
                  <div key={i} className={styles.buttonContainer}>
                    {button.text && button.link &&
                      <Link key={i} to={button.link}>
                        <button className={styles.button}>{button.text}</button>
                      </Link>}
                  </div>
                )
              })}
            </div>
          </Fade>
        </div>
    </section>
  )
}