import React, { useState, useRef, useEffect, useCallback } from 'react'
import styles from './staff.module.css'
import Fade from 'react-reveal/Fade'


export default ({ block }) => {
  const [selected, setSelected] = useState(block.staff && block.staff[0].name);
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const style = typeof window !== 'undefined' ? document.documentElement.style : null;

  useEffect(() => {
    setShow(false);
    if (style && ref.current) {
      style.setProperty('--container-height', ref.current.offsetHeight)
      setTimeout(() => {
        setShow(true)
      }, 100);
    }
  }, [selected])

  return (
    <section className={styles.section}>
      <Fade left duration={500} delay={500} distance='40px'>
        <div className={styles.selectors}>
          <h4 className={styles.type}>Doctors</h4>
          {block.staff && block.staff.filter(staff => !staff.doctorOrStaff).map((doctor, i) => {
            return (
              <img onClick={() => setSelected(doctor.name)} 
                className={styles.smallImage + ` ${selected === doctor.name ? `${styles.smallImageSelected}` : ""}`} key={i} src={doctor.imageOne} alt="" />
            )
          })}
          <div className={styles.staffContainer}>
              <h4 className={styles.type}>Staff</h4>

            {block.staff && block.staff.filter(staff => staff.doctorOrStaff).map((staff, i) => {
              return (
                <button key={i} className={styles.imageButton} onClick={() => setSelected(staff.name)}><img  
                className={styles.smallImage + ` ${selected === staff.name ? `${styles.smallImageSelected}` : ""}`} key={i} src={staff.imageOne} alt="" /></button>
              )
            })}
          </div>
        </div>
      </Fade>
      <Fade right duration={500} delay={500} distance='40px'>
        <div className={styles.infoContainer}>
          {block.staff && block.staff.filter(staff => staff.name === selected).map((staff, i) => {
            return (
              <div key={i} className={styles.info + ` ${show ? `${styles.infoShow}` : ""}`} ref={ref}>
                {staff.imageOne &&
                  <div className={styles.largeImage}><img src={staff.imageOne} alt="" /></div>}
                {staff.imageTwo &&
                  <div className={styles.largeImage}><img src={staff.imageTwo} alt="" /></div>}
                <div className={styles.bio}>
                  <h3 className={styles.name}>{staff.name}</h3>
                  <p className={styles.paragraphOne}>{staff.paragraphOne}</p>
                  <p>{staff.paragraphTwo}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Fade>
    </section>
  )
}