import React, { useState } from "react"
import Header from "../header/header"
import Footer from "../footer/footer"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from 'gatsby'
import Menu from "../menu/menu"
import Main from "../main"
import Insurance from "../insurance/insurance"
import styles from "./layout.module.css"
import Contact from "../contact/contact"

export default function Layout({ page, headerConfig, footerConfig, notFound }) {
  const [open, setOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const { image, smallLogo, alt_text, leftNavItems, rightNavItems, menuOpenLogo, directions, admin } = headerConfig;
  const { phone, address, social, copyright } = footerConfig;
  const links = leftNavItems.concat(rightNavItems);
  const [messageShow, setMessageShow] = useState(false);
  const { site: { siteMetadata: { config: { description, title, titleDivider } } } } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          config {
            description
            title
            titleDivider
          }
        }
      }
    }
  `)

  return (
    <div className={styles.container} id='layout'>
      <Helmet>
        <html lang='en' />
        <title>{title + titleDivider + `${page && page.title || '404: Page Not Found'}`}</title>
        <meta name='description' content={description}/>
      </Helmet>
      <Menu links={links} setOpen={setOpen} open={open} logo={menuOpenLogo}/>
      <Header 
        image={image}
        smallLogo={smallLogo}
        alt_text={alt_text} 
        leftNavItems={leftNavItems}
        rightNavItems={rightNavItems}
        open={open}
        setOpen={setOpen}
        setContactOpen={setContactOpen}
        admin={admin}
      />
      <Contact contactOpen={contactOpen} messageShow={messageShow} setMessageShow={setMessageShow} 
        setContactOpen={setContactOpen} phone={phone} address={address} directions={directions}/>
      <Main page={page}/>
      {notFound &&
        <div className={styles.notFound}>
          <h1>404</h1>
          <h3>Sorry, page not found</h3>
        </div>}
      <Footer phone={phone} address={address} social={social} copyright={copyright} />
    </div>
  )
}