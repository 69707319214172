import React from 'react'
import { Link } from 'gatsby'
import styles from './iconList.module.css'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import ArrowRight from "../../../static/uploads/arrow-right.svg"

export default ({ block }) => {

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Fade top duration={500} delay={500} distance={'40px'} className={styles.list}>
          <h3 className={styles.heading}>{block.heading}</h3>
        </Fade>
        <Fade left duration={500} delay={500} distance={'40px'} className={styles.list}>
          <div className={styles.item}>
            <Flip>
              <img className={styles.icon} src={block.itemOne.icon} alt="" />
            </Flip>
            <p className={styles.description}>{block.itemOne.text}</p>
          </div>
          <div className={styles.item}>
            <Flip>
              <img className={styles.icon} src={block.itemTwo.icon} alt="" />
            </Flip>
            <div className={styles.textContiner}>
              <p className={styles.description}>{block.itemTwo.textOne}</p>
              <Link to={block.itemTwo.link}>
                <p className={styles.textLink}>{block.itemTwo.textTwo}
                  <span className={styles.color}> {block.itemTwo.textColor}</span>
                  <span className={styles.arrow}><ArrowRight className={styles.arrowIcon} /></span>
                </p>
              </Link>
            </div>
          </div>
          <div className={styles.item}>
            <Flip>
              <img className={styles.icon} src={block.itemThree.icon} alt="" />
            </Flip>
            <p className={styles.description}>{block.itemOne.text}</p>
          </div>
        </Fade>
      </div>
    </section>
  )
}