import React from 'react'
import styles from './twoColumnImages.module.css'
import Fade from 'react-reveal/Fade'

export default ({ block }) => {
  
  return (
    <section>
      
        <div className={styles.container + ` ${block.alignment === 'right' ? `${styles.right}` : `${styles.left}`}`}>
          {block.alignment && block.alignment === 'right' &&
          <div></div>}
          {block.images && block.images.map((image, i) => {
            return (
              <Fade key={i} right cascade duration={500} delay={500} distance='40px'>
                <div className={styles.image}><img src={image.image} alt=""/></div> 
              </Fade>
            )
          })}
            {block.alignment && block.alignment === 'left' &&
          <div></div>}
        </div>
     
    </section>
  )
}