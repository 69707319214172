import React from 'react'
import styles from './quote.module.css'
import Fade from 'react-reveal/Fade'

export default ({ block }) => {
  
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Fade right duration={500} delay={500} distance='40px'>
          <h3 className={styles.quote}>{block.quote && block.quote}</h3>
          <p className={styles.quoter}>- {block.quoter && block.quoter}</p>
        </Fade>
      </div>
    </section>
  )
}