import React, { useRef, useState } from 'react'
import styles from './insurance.module.css'
import ArrowRight from "../../../static/uploads/arrow-right.svg"

export default ({ block }) => {
  const ref = useRef(null);
  const middle = useRef(null);
  const last = useRef(null);
  const [current, setCurrent] = useState(0);

  function handleClick() {
    const element = last.current;
    const container = ref.current;
    const mid = middle.current;
    if (element && mid && container && (element.getBoundingClientRect().left < window.innerWidth)) {
      const scroll = container.scrollLeft;
      container.style.scrollBehavior = 'auto';
      container.scrollLeft = container.scrollLeft - (element.getBoundingClientRect().left - mid.getBoundingClientRect().left)  
      container.style.scrollBehavior = 'smooth';
      container.scrollLeft = container.scrollLeft + container.offsetWidth + window.innerWidth / 100 * 2;
    }
    else if (container) {
      container.scrollLeft = container.scrollLeft + container.offsetWidth;
    }  
    
  }
  
  function handleBack() {
    const container = ref.current;
    const mid = middle.current;
    if (mid && container && (container.scrollLeft < 10)) {
      container.style.scrollBehavior = 'auto';
      container.scrollLeft = container.scrollLeft + (mid.getBoundingClientRect().left + mid.offsetWidth);  
      container.style.scrollBehavior = 'smooth';
      container.scrollLeft = container.scrollLeft - container.offsetWidth + window.innerWidth / 100 * 2;
    }
    else if (container) {
      container.scrollLeft = container.scrollLeft - container.offsetWidth;
    }  
  }

  return (
    <section className={styles.section}>
      <h3>Insurance Partners</h3>
      <div className={styles.container} ref={ref}>
        {block.insurance.map((ins, i) => {
          return (
            <div key={i} className={styles.company} ref={middle}>
              <img className={styles.image} src={ins.logo} alt= "" />
            </div>
          )
        })}
        {block.insurance.map((ins, i) => {
          return (
            <div key={i} className={styles.company} ref={last}>
              <img className={styles.image} src={ins.logo} alt= "" />
            </div>
          )
        })}
      </div>
      <button className={styles.back} onClick={handleBack}><ArrowRight className={styles.iconBack} /></button>
        <button className={styles.arrow} onClick={handleClick}><ArrowRight className={styles.icon} /></button>
    </section>
  )
}