import React from 'react'
import styles from './coreServicesSample.module.css'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import { Link } from 'gatsby'
import ArrowRight from "../../../static/uploads/arrow-right.svg"

export default ({ block }) => {
  
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.servicesContainer}>
          <Fade right cascade duration={500} delay={500} distance='40px'>
            {block.services && block.services.map((service, i) => {
              return (
                <div key={i} className={styles.service}>
                  <Flip>
                    <img className={styles.icon} src={service.icon} alt=""/>
                  </Flip>
                  <p className={styles.title}>{service.title}</p>
                  <p className={styles.description}>{service.description}</p>
                </div>
              )
            })}
          </Fade>
        </div>
        {block.buttonText && block.buttonLink &&
          <Fade right duration={500} delay={400} distance='40px'>
            <Link className={styles.linkContainer} to={block.buttonLink}>
              <p className={styles.textLink}>
                {block.buttonText}
                <span className={styles.arrow}><ArrowRight className={styles.arrowIcon} /></span>
              </p>
            </Link>
          </Fade>}
      </div>
    </section>
  )
}