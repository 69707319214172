import React from "react"
import { BottomImage, CoreServices, CoreServicesSample, FormBlock, Hero, IconList, ImageChanger, Insurance, Map, Quote, SingleIcon, Staff, TwoColumnImages, TwoColumnText } from './index'

export default function Main({ page }) {

    return (
      <div>
        {page && page.blocks.map((block, i) => {
          return block.template === 'hero' ? (
            <Hero block={block} key={i} />
          ) : 
            block.template === 'bottom-image' ? (
            <BottomImage block={block} key={i} />
          ) :
            block.template === 'core-services' ? (
            <CoreServices block={block} key={i} />
          ) :
            block.template === 'core-services-sample' ? (
            <CoreServicesSample block={block} key={i} />
          ) :
            block.template === 'form-block' ? (
            <FormBlock block={block} key={i} />
          ) :
            block.template === 'icon-list' ? (
            <IconList block={block} key={i} />
          ) :
            block.template === 'image-changer' ? (
            <ImageChanger block={block} key={i} />
          ) :
            block.template === 'insurance-partners' ? (
            <Insurance block={block} key={i} />
          ) :
            block.template === 'map' ? (
            <Map block={block} key={i} />
          ) :
            block.template === 'quote' ? (
            <Quote block={block} key={i} />
          ) :
            block.template === 'single-icon-text' ? (
            <SingleIcon block={block} key={i} />
          ) :
            block.template === 'two-column-images' ? (
            <TwoColumnImages block={block} key={i} />
          ) :
            block.template === 'staff' ? (
            <Staff block={block} key={i} />
          ) :
            block.template === 'two-column-text' ? (
            <TwoColumnText block={block} key={i} />
          ) : 
          <div key={i}></div>
        })}
      </div>
    )
}