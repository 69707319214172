import React from 'react'
import styles from './coreServices.module.css'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'

export default ({ block }) => {
  
  return (
    <section id="core-services" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.headingContainer}>
          <Fade right duration={500} delay={500} distance='40px'>
            <h3 className={styles.heading}>{block.heading && block.heading}</h3>
            <p className={styles.subHeading}>{block.subHeading && block.subHeading}</p>
          </Fade>
        </div>
          <div className={styles.servicesContainer}>
            <Fade right cascade duration={500} delay={500} distance='40px'>
              {block.services && block.services.map((service, i) => {
                return (
                  <div key={i} className={styles.service}>
                    <Flip>
                      <img className={styles.icon} src={service.icon} alt=""/>
                    </Flip>
                    <p className={styles.title}>{service.title}</p>
                    <p className={styles.description}>{service.description}</p>
                  </div>
                )
              })}
            </Fade>
          </div>
      </div>
    </section>
  )
}