import React from "react"
import styles from "./footer.module.css"
import Rotate from 'react-reveal/Rotate'

export default function Footer({ phone, address, social, copyright }) {
 
  return (
    <section className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.left}>
          <a className={styles.phone} href={`tel:${phone.split(/[ -]+/).join('')}`}>{phone}</a>
          <p className={styles.address}>{address}</p>
        </div>
        <Rotate delay={500}>
          <div className={styles.line}></div>
        </Rotate>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.right}>
          <div className={styles.socialContainer}>
            {social.length > 0 && social.map((icon, i) => {
              return (
                <a key ={i} href={icon.link} target="_blank" rel="noreferrer"><img key={i} className={styles.icon} src={icon.icon} alt="social"/></a>
              )
            })}
          </div>
          <p className={styles.copyright}>{copyright}</p>
          <p className={styles.station8}><a href="http://station8branding.com" target="_blank" rel="noreferrer">Web design by Station8</a></p>
        </div>
      </div>
    </section>
  )
}