import React, { useRef, useEffect, useState } from "react"
import styles from "./contact.module.css"
import ArrowRight from "../../../static/uploads/arrow-right.svg"
import Recaptcha from 'react-google-recaptcha'

export default function Contact ({ setContactOpen, contactOpen, phone, directions, messageShow, setMessageShow }) {
  const ref = useRef(null);
  const recaptchaRef = useRef(null);
  const [recaptchaValue, setRecaptchaValue] = useState('')
  const [validEmail, setValidEmail] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [referral, setReferral] = useState('');
  const [message, setMessage] = useState('');

  const encode = data => Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&')

  useEffect(() => {
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              setContactOpen(false);
          }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref, setContactOpen]);

  function handleSubmit(e) {
    e.preventDefault();
    if (!validEmail) {
      setMessageShow(true);
      return
    } 

    !recaptchaValue && setShowError(true) && setMessageShow(true);
    const form = e.target;
    recaptchaValue && window.fetch('https://send.pageclip.co/wVBdes9g4D6l5QFbyUGhvXfwDsgNNTi1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'type': 'general',
        'name': name,
        'phone': userPhone,
        'email': email,
        'referral': referral,
        'message': message
      })
    })
      .then(() => {
        setFormSubmitted(true); 
        setMessageShow(true);
        setName('');
        setEmail('');
        setReferral('');
        setMessage('');
        setUserPhone('');
        setTimeout(() => {
          setContactOpen(false)
        }, 3000);
      })
      .catch((error) => console.error(error))
  }

  function handleChangeEmail(e) {
    setEmail(e.target.value);
    setValidEmail(e.target.value.match(/\S+@\S+\.\S+/))
  }

  function onChange(value) {
    setRecaptchaValue(value);
    value && setShowError(false);
  }

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'name')
      setName(value);
    else if (name === 'referral')
      setReferral(value);
    else if (name === 'message')
      setMessage(value);
    else if (name === 'phone')
      setUserPhone(value);
  }

  return (
      <section className={styles.section + `${(contactOpen) ? ` ${styles.contactOpen}` : ` ${styles.contactClosed}`}`} ref={ref}>
        <div className={styles.x} onClick={() => setContactOpen(false)}>
          <div className={styles.xTop}></div>
          <div className={styles.xBottom}></div>
        </div>
        <form className={styles.form + ' pageclip-form'}  method='post' name='contact-form' onSubmit={handleSubmit}>
          <h1 className={styles.heading}>Contact Us</h1>
          <fieldset className={styles.fieldset}>
            <label className={styles.label} htmlFor="name">Name</label>
            <input className={styles.input} value={name} id="name" name="name" onChange={handleChange} required/>
          </fieldset>
          <fieldset className={styles.fieldset}>
            <label className={styles.label} htmlFor="email">Email</label>
            <input className={styles.input} value={email} id="email" name="email" onChange={handleChangeEmail} required/>
          </fieldset>
          <fieldset className={styles.fieldset}>
            <label className={styles.label} htmlFor="email">Phone</label>
            <input className={styles.input} value={userPhone} id="phone" name="phone" onChange={handleChange} />
          </fieldset>
          <fieldset className={styles.fieldset}>
            <label className={styles.label} htmlFor="referral">Referring Doctor</label>
            <input className={styles.input} value={referral} id="referral" name="referral" onChange={handleChange} />
          </fieldset>
          <fieldset className={styles.fieldset}>
            <label className={styles.label} htmlFor="message">Message</label>
            <textarea className={styles.input} value={message} id="message" name="message" onChange={handleChange} />
          </fieldset>
          <div className={styles.buttonContainer}>
            <Recaptcha className={styles.recaptcha} ref={recaptchaRef} sitekey='6LfYNgMaAAAAANN2Cw4BRxMAqwHQ0TYuroV0IN_6' onChange={onChange} />
            {/* <Recaptcha className={styles.recaptcha} name='test-recaptcha' ref={recaptchaRef} sitekey='6Le_TwMaAAAAADm5BKA6PrhdufUgYBWK0m109cHB' onChange={onChange} /> */}
            <button className={styles.button}>Send</button>
            <p className={styles.message + ` ${messageShow ? `${styles.messageShow}`: ""}`}>{!validEmail ? 'Please enter valid email' : showError ? 'Challenge must be completed' : formSubmitted ? 'Thank you for contacting us' : ''}</p>
          </div>
        </form>
        <div className={styles.contact}>
          <a className={styles.phone} href={`tel:${phone.split(/[ -]+/).join('')}`}>{phone}</a><br></br>
          <a className={styles.phone} href={`mailto:admin@bellemeadeendo.com`}>admin@bellemeadeendo.com</a><br></br>
          <a className={styles.address} target="_blank" rel="noreferrer" href={directions}>get directions<span className={styles.arrow}><ArrowRight className={styles.icon} /></span></a>
        </div>
      </section>
    )
}